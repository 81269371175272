import React from "react"
import ReactFullpage from "@fullpage/react-fullpage"

import { Footer } from "components/Footer"
import { Gallery } from "components/Gallery"

import * as styles from "./styles.module.scss"

const Title = ({ children }) => <h2 className={styles.Title}>{children}</h2>

const Discription = ({ children }) => (
  <p className={styles.Discription}>{children}</p>
)

const ConceptSection = () => (
  <section className={styles.ConceptSection_Container}>
    <div className={styles.ConceptSection_TextContainer}>
      <div className={styles.ConceptSection_Logo}>
        <img
          src={"/images/icon_white.png"}
          className={styles.ConceptSection_LogoImg}
          alt="baw(美容室)のコンセプト"
        />
      </div>

      <Discription>
        Bawとは、ポーランド語で楽しむという意味。
        <br />
        シンプルでどんな状況にも合う、かつ飽きのこない遊び心をプラス。
        シンプルな中に隠された楽しみ、そんなヘアデザインで新たなライフスタイルを提供したい。
        <br />
        <br />
        お客様が思いがけない新しい自分自身の魅力と出逢い、より素敵な毎日を過ごしていただけますように、ヘアデザインを通じてお手伝いさせていただきたい、
        そんなサロンでありたいと思っています。
      </Discription>
    </div>
  </section>
)

const MenuConceptSection = () => (
  <section className={styles.MenuConceptSection_Container}>
    <div className={`Slide ${styles.MenuConceptSection_CutSlide}`}>
      <dl className={styles.MenuConceptSection_TextContainer}>
        <dt className={styles.MenuConceptSection_Title}>Cut</dt>
        <dd>
          <Discription>
            Bawではお客様とのイメージの共有を大切にしています。
            <br />
            <br />
            カットに入る前にまずしっかりとカウンセリングを行い普段のお手入れの仕方や髪のお悩みなどをお聞きして一人一人に合ったスタイルの提案をさせていただきます。
            <br />
            骨格、髪質、生え癖などしっかりとみきわめ、状況に合わせた技法でカットしていくため自宅でも簡単に再現できます。時間が経ってもヘアスタイルが崩れにくくカットの持ちの良さを実感して頂けます。
          </Discription>
        </dd>
      </dl>
    </div>
    <div className={`Slide ${styles.MenuConceptSection_ColorSlide}`}>
      <dl className={styles.MenuConceptSection_TextContainer}>
        <dt className={styles.MenuConceptSection_Title}>Color And Perm</dt>
        <dd>
          <Discription>
            お客様の髪質、スタイルイメージに合う薬剤を選定していきます。
            <br />
            ケラチンやタンパク質を髪に補給する前処理をおこない、日常生活による日焼、ドライヤーやアイロンによる乾燥やダメージを補います。
            また、後処理では、薬剤の残留による頭皮のトラブルや毛髪のダメージを最小限に抑えます。
            これらをしっかり行う事によりパーマ、カラーの持ちを良くします。
            <br />
            また、ポイントパーマやデザインカラーなど極力ダメージを抑えたスタイルもご相談下さい。
          </Discription>
        </dd>
      </dl>
    </div>
    <div className={`Slide ${styles.MenuConceptSection_OtherSlide}`}>
      <dl className={styles.MenuConceptSection_TextContainer}>
        <dt className={styles.MenuConceptSection_Title}>Other</dt>
        <dd>
          <Discription>
            清田区住宅街の一角にある美容室
            <br />
            <br />
            完全予約制でカウンセリングから仕上げまでマンツーマンで担当いたします。
            『美術館のような美容室』をテーマにした空間は、リラックス、集中、そして刺激が共存するような場所。
            忙しい日常から離れ、ご自身の美意識に目を向ける時間を過ごせる場所になればと思います。
            <br />
            <br />
            初めてご来店のお客様は、カウンセリングにお時間を頂戴しておりますので余裕を持ってご来店いただくことをおすすめいたします。
          </Discription>
        </dd>
      </dl>
    </div>
  </section>
)

const GallerySection = () => (
  <section className={styles.GallerySection_Container}>
    <div className={styles.GallerySection_Gallery}>
      <Gallery />
    </div>
  </section>
)

export default function Home() {
  return (
    <>
      <ReactFullpage
        //fullpage options
        licenseKey={"240F6328-47E64C97-82A0A364-AE0D2783"}
        scrollingSpeed={700} /* Options here */
        sectionSelector="section"
        slideSelector="div.Slide"
        render={({ state, fullpageApi }) => {
          return (
            <ReactFullpage.Wrapper>
              <ConceptSection />
              <MenuConceptSection />
              <GallerySection />

              <section className="fp-auto-height">
                <Footer />
              </section>
            </ReactFullpage.Wrapper>
          )
        }}
      />
    </>
  )
}
