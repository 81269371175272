import React from "react"
import * as styles from "./styles.module.scss"

import { Link } from "gatsby"

export const Footer = ({ children }) => (
  <div className={styles.Container}>
    <div className={styles.LogoBox_Container}>
      <Link to="/" className={styles.LogoBox_Link}>
        <img
          src={"/images/icon_white.png"}
          className={styles.LogoBox_Icon}
          alt="札幌市清田区里塚の美容室bawのロゴ"
        />
        <img
          src={"/images/logo_white.png"}
          className={styles.LogoBox_Img}
          alt="札幌市清田区里塚の美容室baw"
        />
      </Link>

      <div className={styles.LogoBox_SNSs}>
        <a href="https://www.instagram.com/baw__sapporo/">
          <img
            src={"/images/instagram256.png"}
            className={styles.LogoBox_SNSIcon}
            alt="美容室のinstagramへ"
          />
        </a>
        {/*
          <a href="https://timeline.line.me/user/_dfovOKZByyrgbnR_dEfbVSIUl8Ab1Ye0lV8IRZU">
            <img src={ 'images/line256.png'} className={ styles.LogoBox_IconSNS } alt="Line" />
          </a>
          */}
      </div>
    </div>

    <div className={styles.InfoBox_Container}>
      <div className={styles.InfoBox_Box}>
        <div className={styles.InfoBox_Contents}>
          <img
            src={"/images/pin.png"}
            className={styles.InfoBox_Icon}
            alt="美容室の住所"
          />
          <p>〒004-0801</p>
          <p>札幌市清田区里塚1条2丁目7-3</p>
        </div>
        <div className={styles.InfoBox_Contents}>
          <img
            src={"/images/telephone.png"}
            className={styles.InfoBox_Icon}
            alt="美容室の電話番号"
          />
          <p className={styles.InfoBox_TelNumber}>011-796-3056</p>
        </div>
      </div>
      <dl className={styles.InfoBox_Box}>
        <dt className={styles.BusinessHoursTitle}>- 営業時間 -</dt>
        <dd>
          <p>平日 10:00～20:00</p>
          <p>土曜・日曜・ 10:00～20:00</p>
          <p>定休日 火曜日</p>
        </dd>
      </dl>
    </div>

    <p className={styles.Copyrights}>© Baw ALL RIGHTS RESERVED.</p>
  </div>
)
